import React from 'react'
// import firstCarousel from "../../Images/MainCarousel/first.jpg"
import sprokets from "../../Images/Services/sprockets.jpg"
import gears from "../../Images/Services/gears.jpg"
import spineshaft from "../../Images/Services/splineshaftspic.jpg"
import { Link } from "gatsby";

function ServiceBanner() {
	
    return (
        <div>


<div className="section " style={{backgroundColor:"#96271C"}} >
		<div className="content-wrap pt-0">
			<div className="container">
				
				<div className="row overlap-row">
					
					<div className="col-sm-12 col-md-12 col-lg-4">
						<div className="box-image-3 text-black">
							<div className="media-img">
								<img src={sprokets} alt="Sprockets" className="img-fluid" />
							</div>
							<div className="body-content">
								<h4>Sprockets</h4>
								<p>We are a major manufacturer and supplier of a variety of Sprockets which are highly demanded by various industries across India…..</p>
								<Link to="/category/sprockets">Learn more</Link> 
							</div>
						</div>
					</div>
					
					<div className="col-sm-12 col-md-12 col-lg-4">
						<div className="box-image-3 text-black">
							<div className="media-img">
								<img src={gears} alt="gears" className="img-fluid" />
							</div>
							<div className="body-content">
								<h4>Gears</h4>
								<p>We are engaged in providing an exclusive range of Gears which are demanded by industry on large scale. These are manufactured under the worthy guidance of experts.</p>
								<Link to="/category/gears">Learn more</Link> 
							</div>
						</div>
					</div>
					
					<div className="col-sm-12 col-md-12 col-lg-4">
						<div className="box-image-3 text-black">
							<div className="media-img">
								<img src={spineshaft} alt="spineshaft" className="img-fluid" width="auto" />
							</div>
							<div className="body-content">
								<h4>Spline Shafts</h4>
								<p>Manufactured under strict quality control, our range of Spline Shafts is demanded by clients from all over India based on speciﬁcations provided by quality engineers.</p>
								<Link to="/category/splineshaft">Learn more</Link>  
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
    
        </div>
    )
}


export default ServiceBanner
