import React, { useEffect } from "react"
import { Link } from "gatsby"

import "bootstrap/dist/css/bootstrap.min.css"
import "../styles/style.css"

// import CarouselBanner from "../Components/Home/CarouselBanner";
import ServiceBanner from "../Components/Home/ServiceBanner"
import About from "../Components/Home/AboutHome"
// import Services from "../Components/Home/Services";
import CTA from "../Components/Home/CTA"
// import Testimony from "../Components/Home/Testimony";
import Layout from "../Components/reusable/Layout"

// import logoipsum from "../Images/background/dummy-logo-1b.png"

import Loadable from "react-loadable"
import store from "store"
const loader = () => <div>Loading...</div>

const MyLoadableCarousel = Loadable({
  loader: () => import("../Components/Home/CarouselBanner"),
  loading: loader,
})

// const MyLoadableSmallCarousel = Loadable({
// 	loader: () => import("../Components/reusable/BrandCarousel"),
// 	loading: loader,
//   })

export default function Home() {
  useEffect(() => {
    store.set("usercartpref", {
      pref: "",
    })
  }, [])

  return (
    <Layout>
      <MyLoadableCarousel />
      <ServiceBanner />
      <About />
      <CTA />

      {/* <MyLoadableSmallCarousel /> */}

      {/* <Testimony /> */}

      {/* <!-- CTA --> */}
      <div className="section  " style={{ backgroundColor: "rgb(150, 39, 28)" }}>
        <div className="content-wrap py-5">
          <div className="container">
            <div className="row align-items-center cta-block-1">
              <div className="col-sm-8 col-md-8">
                <h3 className="my-1 text-black">
                  Don't hesitate to contact us any time
                </h3>
              </div>

              <div className="col-sm-4 col-md-4 my-3">
                <div className="tbutton">
                  <Link to="/contact" className="btn btn-secondary">
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
