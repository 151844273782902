import { Link } from 'gatsby'
import React from 'react'
import firstCarousel from "../../Images/MainCarousel/first.jpg"
import homeabout from "../../Images/background/home-about.jpg"

function AboutHome() {
    return (
      <div className="section">
        <div className="content-wrap">
          <div className="container">
            <div className="row">
              <div className="col-sm-4 col-md-4">
                <h4 className="section-heading text-black no-after mb-3">
                  Why <span className="text-primary">Us</span>
                </h4>

                <h3
                  className="text-black"
                  style={{ fontSize: "28px", lineHeight: "38px" }}
                >
                  Leading manufacturer and supplier of Sprockets, Gears and
                  Spline Shafts
                </h3>
                <p>
                  We offer these products in a variety of sizes, dimensions and
                  designs with customization done to cater to the specific
                  demands of the clients.
                </p>
                <Link to="/company/about" className="btn btn-secondary">
                  Read More
                </Link>
                <div className="spacer-30"></div>
              </div>
              <div className="col-sm-8 col-md-8 pleft">
                <div className="img-subsco">
                  <img src={homeabout} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}

export default AboutHome
