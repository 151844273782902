import React from 'react';
import BannerBg from "../../Images/background/home-bg.jpg"


function CTA() {
    return (
        <div className="section bgi-cover-center bg-overlay-dark" data-background={BannerBg} style={{backgroundImage:`url(${BannerBg})`}}>
		<div className="content-wrap">
			<div className="container">

				<div className="row align-items-center">
					<div className="col-sm-12 col-md-8 offset-md-2">
						<div className="cta-info">
							<h2 className="text" style={{color:"#ff210b"}}>Quality Construction. Honest service. Great value</h2>
							<p className="text-white">Our professional team works to increase productivity and cost effectiveness on the market</p>
						</div>
					</div>
				</div>

			</div>
		</div>
	</div>
    )
}

export default CTA
